@tailwind base;
@tailwind components;
@tailwind utilities;

.main-container {
  @apply container max-w-6xl mx-auto p-4 sm:p-5;
}

.block-section {
  @apply bg-white shadow rounded-xl;
}

.block-section-profile {
  @apply bg-white shadow rounded-xl rounded-t-none;
}

.block-title {
  @apply text-lg font-semibold mb-5;
}

.item-section {
  @apply space-y-4 sm:space-y-0 sm:flex sm:items-start sm:space-x-4;
}

.block-header-info {
  @apply flex space-x-1.5 items-center text-sm text-gray-400 font-medium;
}

.block-header {
  @apply space-y-4 sm:space-y-0 sm:flex sm:justify-between;
}

.me-photo {
  @apply w-20 h-20 rounded-xl border-2 border-white shadow absolute -top-10;
}

.download-cv-btn {
  @apply w-full py-2 rounded-l-lg text-base font-semibold bg-blue-500 text-white transition duration-200 group-hover:bg-blue-600;
}

.download-cv-btn-icon {
  @apply px-5 py-2 rounded-r-lg bg-blue-600 text-white;
}

.skill-tag {
  @apply m-1 px-4 py-1.5 rounded-lg text-sm font-medium text-blue-500 bg-blue-100;
}

.link-out {
  @apply flex items-center space-x-1 text-blue-500 font-medium;
}

.social-link-out-hover {
  @apply transition duration-300 hover:text-blue-600;
}

.menu-link {
  @apply inline-flex pb-5 border-b-2 border-transparent;
}

.menu-link-active {
  @apply inline-flex pb-5 border-b-2 text-blue-500 border-blue-500;
}

.menu-link-hover {
  @apply transition duration-300 hover:text-blue-600 hover:border-blue-600;
}

.company-block-logo {
  @apply flex-shrink-0 w-12 h-12 flex items-center justify-center rounded-xl text-white;
}

.job-type-badge {
  @apply inline-flex px-2 py-0.5 text-xs font-medium capitalize rounded-lg text-blue-500 bg-blue-100;
}

/* Dark Mode Styles */

.dark html,
.dark body {
  border-color: #736b5e;
  color: #e8e6e3;
}

.dark .dark-background {
  background-color: rgb(30, 32, 34);
}

.dark .block-section,
.dark .block-section-profile {
  background-color: rgb(24, 26, 27);
}

.dark .block-title {
  color: #e8e6e3;
}

.dark .block-header-info {
  color: rgb(175, 169, 158);
}

.dark .skill-tag {
  background-color: rgb(34, 37, 39);
  color: rgb(69, 159, 246);
}

.dark .job-type-badge {
  background-color: rgb(34, 37, 39);
  color: rgb(69, 159, 246);
}

.dark .download-cv-btn {
  background-color: rgb(16, 67, 178);
}

.dark .download-cv-btn:hover {
  background-color: rgb(16, 60, 155);
}

.dark .download-cv-btn-icon {
  background-color: rgb(16, 60, 155);
  color: rgb(232, 230, 227);
}

.dark .link-out {
  color: rgb(69, 159, 246);
}

.dark .border-gray-200 {
  border-color: rgb(54, 59, 61);
}

.dark .text-gray-600 {
  color: rgb(177, 170, 160);
}

.dark .text-gray-400 {
  color: rgb(175, 169, 158);
}
